import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {SearchBox} from 'react-instantsearch';
import PageLayoutComponent from '../../plasmic_components/PageLayoutComponent';
import InstantSearchProvider from './InstantSearchProvider';
import RefinementListWithAlgolia from './RefinementListWithAlgolia';
import StatsWithAlgolia from './StatsWithAlgolia';
import MenuSelectwithAlgolia from './MenuSelect/MenuSelectwithAlgolia';
import FiltersMenu from './FiltersMenu';
import GridWithAlgolia from './Grid/GridWithAlgolia';
import SortBy from './SelectWithAlgoliaSortBy';

import CurrentRefinementsWithAlgolia from './CurrentRefinementsWithAlgolia';
import {Plasmic_017CtaBlock as CtaBlock} from '../../plasmic_components/plasmic/placeit/Plasmic_017CtaBlock';
import Text from '../UI/Atoms/Typography/Text';
import {IcChevronRightCurved} from '../UI/Atoms/Icons';
import SeoTag from '../SEO/SeoTag';
import {
  HEADER_TEXT_DATA,
  PAGE_TYPE_MAIN_SEARCH,
  PAGE_TYPE_CATEGORY,
} from '../../lib/category-pages-constants';
import VirtualRefinementList from './VirtualRefinementList';
import NoResultsPage from './NoResultsPage';

const SHOW_FILTER_CATEGORY = 'mockups';
const getPageType = query => {
  return query.trim() !== '' ? PAGE_TYPE_MAIN_SEARCH : PAGE_TYPE_CATEGORY;
};

const headerBreadcrumb = categoryName => {
  return (
    <Text
      size="2xl"
      className="flex w-full bg-gray-ce-page p-4 pb-4 pt-5 text-left text-xl font-bold"
    >
      <span className="flex flex-grow font-bold capitalize text-gray-900">
        <a href="https://placeit.net" className="font-semibold text-gray-400">
          Home
        </a>
        <IcChevronRightCurved className="relative top-0.5 mx-2 h-5 w-5 stroke-cold-gray-800" />
        {HEADER_TEXT_DATA[categoryName].name}
      </span>
      <span className="text-end font-bold text-zinc-500">
        {HEADER_TEXT_DATA[categoryName].slogan}
      </span>
    </Text>
  );
};

const headingData = (categoryName, query) => {
  return query.trim() !== '' ? (
    <StatsWithAlgolia className="min-h-[60px] w-full bg-gray-ce-page p-4 pb-3 pt-5 text-left text-xl text-black" />
  ) : (
    headerBreadcrumb(categoryName)
  );
};

function CategoryPage({query, category, SEO, CEHeading}) {
  const enableFilters = category === SHOW_FILTER_CATEGORY;
  const [showFilters, setShowFilters] = useState(enableFilters);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    if (window.pageMetadata) {
      Object.assign(window.pageMetadata, {
        pageCategory: SEO.pageCategory,
        pageType: SEO.pageType,
      });
    }
  }, [query]);

  const categoryName = category;
  const categoryInfo = HEADER_TEXT_DATA[categoryName];

  let categoryQuery = `AND category_name:"${categoryInfo.name}"`;

  if (categoryName === 'gaming-templates') {
    categoryQuery = `AND (stage_tags:"Gaming" OR stage_tags:"Twitch Emote" OR stage_tags:"Animal Crossing" OR stage_tags:"Apex Legends" OR stage_tags:"Call of Duty" OR stage_tags:"Counter-Strike" OR stage_tags:"DOTA" OR stage_tags:"Fall Guys" OR stage_tags:"FIFA" OR stage_tags:"Fortnite" OR stage_tags:"Free Fire" OR stage_tags:"GTA" OR stage_tags:"League of Legends" OR stage_tags:"Gaming" OR stage_tags:"Animal Crossing" OR stage_tags:"Apex Legends" OR stage_tags:"Call of Duty" OR stage_tags:"Counter-Strike" OR stage_tags:"DOTA" OR stage_tags:"Fall Guys" OR stage_tags:"FIFA" OR stage_tags:"Fortnite" OR stage_tags:"Free Fire" OR stage_tags:"GTA" OR stage_tags:"League of Legends" OR stage_tags:"Minecraft" OR stage_tags:"Mobile Legends" OR stage_tags:"Overwatch" OR stage_tags:"PUBG" OR stage_tags:"Rainbow Six Siege" OR stage_tags:"Roblox" OR stage_tags:"Rocket League" OR stage_tags:"Starcraft" OR stage_tags:"The Last Of Us" OR stage_tags:"Valorant" OR stage_tags:"Warcraft" OR stage_tags:"World Of Tanks" OR stage_tags:"Action Adventure" OR stage_tags:"MOBA" OR stage_tags:"RPG" OR stage_tags:"Simulation" OR stage_tags:"Shooters")`;
  }

  if (categoryName === 'all') {
    categoryQuery = '';
  }

  return (
    <PageLayoutComponent>
      <SeoTag
        config={categoryInfo.SEO}
        pageCategory={SEO.pageCategory}
        pageType={SEO.pageType}
        type="tag"
      />
      <InstantSearchProvider
        data-plasmic-name="instantSearchProvider"
        filters={`is_published=1 ${categoryQuery}`}
        indexName="Stage_production"
        query={query}
      >
        <SearchBox className="hidden" />

        <VirtualRefinementList attribute="model_tags" operator="and" />

        <div className={`${showHeader ? 'h-auto w-full overflow-auto' : 'h-0 overflow-hidden'}`}>
          {CEHeading || headingData(categoryName, query)}
        </div>

        <NoResultsPage
          category={category}
          setShowFilters={setShowFilters}
          setShowHeader={setShowHeader}
        />

        <div className="h-auto w-full overflow-auto">
          <FiltersMenu
            showHeader={showHeader}
            select={
              <SortBy classNames={{select: 'mb-4 w-full'}} defaultRefinement="Stage_production" />
            }
            topFilters={
              showFilters ? (
                <div className="gap-y-0">
                  <div className="flex pb-2">
                    <MenuSelectwithAlgolia
                      attribute="age_tags"
                      dropdownTitle="Age"
                      className="mx-1"
                      operator="and"
                    />
                    <MenuSelectwithAlgolia
                      attribute="gender_tags"
                      dropdownTitle="Gender"
                      className="mx-1"
                      operator="and"
                    />
                    <MenuSelectwithAlgolia
                      attribute="ethnicity_tags"
                      dropdownTitle="Ethnicity"
                      className="mx-1"
                      operator="and"
                    />
                  </div>
                  <CurrentRefinementsWithAlgolia />
                </div>
              ) : (
                <div className="gap-y-0">
                  <CurrentRefinementsWithAlgolia />
                </div>
              )
            }
            refinementList={
              <div className="pb-4 pr-1 pt-2">
                <RefinementListWithAlgolia
                  title="Templates"
                  attribute="device_tags"
                  limit={10}
                  showMoreLimit={60}
                  showMore
                  operator="and"
                />
                <RefinementListWithAlgolia
                  title="Tags"
                  attribute="stage_tags"
                  operator="and"
                  limit={100}
                />
              </div>
            }
          >
            <div className="overflow-hidden">
              <GridWithAlgolia paginationType="normal" />
            </div>
          </FiltersMenu>
        </div>
      </InstantSearchProvider>
      <div
        className={`${
          showHeader
            ? 'h-auto w-full overflow-auto bg-gray-ce-page py-14 pb-16'
            : 'h-0 overflow-hidden'
        }`}
      >
        <CtaBlock />
      </div>
    </PageLayoutComponent>
  );
}

export {CategoryPage, getPageType};

CategoryPage.defaultProps = {
  query: '',
  category: '',
  SEO: {
    pageCategory: '',
    pageType: '',
  },
  CEHeading: null,
};

CategoryPage.propTypes = {
  query: PropTypes.string,
  category: PropTypes.string,
  SEO: PropTypes.shape({
    pageCategory: PropTypes.string,
    pageType: PropTypes.string,
  }),
  CEHeading: PropTypes.shape(),
};
