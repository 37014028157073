import connectStats from 'instantsearch.js/es/connectors/stats/connectStats';
import {useConnector} from 'react-instantsearch';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {Trans} from '@lingui/macro';
import Image from 'next/image';
import {PlasmicElementsBanner as ElementsBanner} from '../../plasmic_components/plasmic/placeit/PlasmicElementsBanner';
import MarketingListsComponent from '../../plasmic_components/MarketingListsComponent';
import Text from '../UI/Atoms/Typography/Text';
import {NO_RESULTS_BEST_SELLING_CLICK, trackEvent} from '../../lib/analytics';
// eslint-disable-next-line no-unused-vars
import sendPlasmiCardEvent from '../../lib/utils/sendPlasmicCardEvent';
// used by plasmic MarketingListsComponent in this page
const SHOW_FILTER_CATEGORY = 'mockups';
function NoResultsPage({setShowFilters, category, setShowHeader}) {
  const [showNoResults, setShowNoResults] = useState(false);
  const ZERO_RESULTS = 0;
  const bestSellingClickHandler = () => {
    trackEvent(NO_RESULTS_BEST_SELLING_CLICK);
  };

  const useStats = props => {
    return useConnector(connectStats, props);
  };

  const stats = useStats();

  useEffect(() => {
    if (stats.nbHits === ZERO_RESULTS) {
      setShowFilters(false);
      setShowNoResults(true);
      setShowHeader(false);
      return;
    }
    if (category === SHOW_FILTER_CATEGORY) {
      setShowFilters(true);
    }
    setShowNoResults(false);
    setShowHeader(true);
  }, [stats]);

  return (
    <div className={`${showNoResults ? 'h-auto w-full overflow-auto' : 'h-0 overflow-hidden'}`}>
      <div className="flex w-full flex-col pt-8 md:px-28 lg:flex-row">
        <div className="flex-1 justify-center lg:border-r lg:border-gray-200 2xl:flex 2xl:items-center 2xl:justify-end">
          <div className=" flex justify-center 2xl:inline-block 2xl:flex-initial">
            <div className="h-16 w-16 pt-1.5 md:h-28 md:w-28 2xl:h-28 2xl:w-24">
              <Image src="/static/magnifying-glass-gray.svg" width={104} height={102} />
            </div>
          </div>
          <Text
            className="px-8 pb-6 pt-4 text-center !font-poppins text-xl leading-8 text-gray-300 md:text-3xl md:!leading-10 2xl:mr-4 2xl:inline-block 2xl:w-full 2xl:max-w-lg 2xl:px-5 2xl:text-left 2xl:text-3xl"
            weight="bold"
          >
            <Trans>Sorry! No templates matched your search</Trans>
          </Text>
        </div>
        <div className="flex-1 px-8 md:pl-16">
          <Text
            className="w-full text-center text-gray-500 md:text-left"
            size="2xl"
            weight="semibold"
          >
            <Trans>Search Tips</Trans>:
          </Text>
          <ul className="list-disc pl-4 marker:text-gray-500 md:w-5/6">
            <li>
              <Text className="pt-4 leading-7 text-gray-500" size="xl" weight="normal">
                <Trans>Double-check the spelling on your query</Trans>.
              </Text>
            </li>
            <li>
              <Text className="pt-4 leading-7 text-gray-500" size="xl" weight="normal">
                <Trans>Try with a general term when a specific one doesn’t show results</Trans>.
              </Text>
            </li>
            <li>
              <Text className="pt-4 leading-7 text-gray-500" size="xl" weight="normal">
                <Trans>
                  Click on the search bar’s drop-down menu to search in a different category
                </Trans>
                .
              </Text>
            </li>
          </ul>
          <Text
            className="w-full pt-4 text-center leading-7 text-gray-400 lg:text-left"
            size="lg"
            weight="normal"
          >
            <Trans>Try a different search or take a look at our</Trans>&nbsp;
            <a
              href="https://placeit.net/?sortby=best-selling"
              onClick={bestSellingClickHandler}
              className="block w-full text-secondary-500 underline lg:inline"
            >
              <Trans>best selling templates</Trans>.
            </a>
          </Text>
        </div>
      </div>
      <MarketingListsComponent />
      <ElementsBanner className="!pt-0" />
    </div>
  );
}

export default NoResultsPage;

NoResultsPage.defaultProps = {
  setShowFilters: () => {},
  setShowHeader: () => {},
  category: 'all',
};

NoResultsPage.propTypes = {
  setShowFilters: PropTypes.func,
  setShowHeader: PropTypes.func,
  category: PropTypes.string,
};
