// eslint-disable-next-line import/prefer-default-export
const HEADER_TEXT_DATA = {
  mockups: {
    slogan: 'The largest free mockup library online',
    name: 'Mockups',
    SEO: {
      title: 'Mockup Generator | Try + 40k Mockups for Free | Placeit',
      description:
        'Display your t-shirt designs with our apparel mockups, your app screenshot on one of our iPhone mockups and your website redesign on one of our iMac or MacBook mockups, it&#x27;ll only take a few seconds to add your image to the mockup without Photoshop.',
      canonical: 'https://placeit.net/c/mockups',
    },
  },
  all: {
    slogan: 'Customizable Templates',
    name: 'All',
    SEO: {
      title: 'Make Mockups, Logos, Videos and Designs in Seconds',
      description:
        'Get unlimited downloads on all our 120K templates! You can make a logo, video, mockup, flyer, business card and social media image in seconds right from your browser.',
      canonical: 'https://placeit.net/',
    },
  },
  'design templates': {
    slogan: 'Customizable Design Templates',
    name: 'Design Templates',
    SEO: {
      title: 'Design Templates | T-Shirts, Insta Stories, Banners &amp; More | Placeit',
      description:
        'Design a t-shirt, flyer, business card and even the perfect book cover in a matter of seconds. How? Just customize our design templates to create beautiful designs to promote your brand or business without going through the hassle of hiring a designer.',
      canonical: 'https://placeit.net/c/design-templates',
    },
  },
  videos: {
    slogan: 'Customizable Video Templates',
    name: 'Videos',
    SEO: {
      title: 'Video Maker | Make Instant Videos Easily | Placeit',
      description:
        'Give your social media a fresh look with video content you can create straight from your browser! Browse our library of video templates for social media and take your brand to the next level. No need to use After Effects or other complex tools, just our online video maker.',
      canonical: 'https://placeit.net/c/videos',
    },
  },
  logos: {
    slogan: 'Customizable Logo Templates',
    name: 'Logos',
    SEO: {
      title: 'Logo Maker | Choose from more than 3600+ logo templates | Placeit',
      description:
        'Designing a logo for new brand or business is no hassle, just use our logo maker to create a custom logo in seconds, straight from your browser and without hiring a designer. There are hundreds of different logo templates, choose the one that represents your business.',
      canonical: 'https://placeit.net/c/logos',
    },
  },
  'gaming-templates': {
    slogan: 'Templates to Level up Your Game!',
    name: 'Gaming Templates',
    SEO: {
      title: 'Free Gaming Templates for your Channel - Placeit',
      description:
        'Create amazing designs for your Gaming Channel in seconds by using Placeit! Try them out now and start streaming in no time.',
      canonical: 'https://placeit.net/gaming-templates',
    },
  },
};

const PAGE_TYPE_MAIN_SEARCH = 'Main Search';

const PAGE_TYPE_CATEGORY = 'Category Page';

export {HEADER_TEXT_DATA, PAGE_TYPE_MAIN_SEARCH, PAGE_TYPE_CATEGORY};
