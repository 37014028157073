import {useState, useEffect} from 'react';
import {useConnector} from 'react-instantsearch';
import connectStats from 'instantsearch.js/es/connectors/stats/connectStats';
import Stats from './Stats';

function useStats(props) {
  return useConnector(connectStats, props);
}

export default function StatsWithAlgolia(props) {
  const [isMounted, setIsMounted] = useState(false);
  const stats = useStats();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsMounted(true);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return <Stats hits={stats.nbHits} query={stats.query} mounted={isMounted} {...props} />;
}
