import PropTypes from 'prop-types';
import Text from '../UI/Atoms/Typography/Text';

export default function Stats({className, hits, query, mounted}) {
  return (
    <div className={className}>
      <Text size="2xl" as="span" weight="bold">
        {mounted && hits.toLocaleString()}
      </Text>
      <Text size="2xl" as="span">
        {mounted && query.trim().length && ' results for '}
        {mounted && query.trim().length <= 0 && ' results '}
      </Text>
      <Text size="2xl" as="span" weight="bold">
        {mounted && query}
      </Text>
    </div>
  );
}

Stats.defaultProps = {
  className: '',
  mounted: false,
};

Stats.propTypes = {
  className: PropTypes.string,
  hits: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired,
  mounted: PropTypes.bool,
};
